import { makeAutoObservable } from 'mobx';
import { pushTypes, screens } from '../types/enums';
import { io, Socket } from 'socket.io-client';
import Chat from './Chat';
import Actions from './Actions';
import Utils from './Utils';
import Requests from './Requests';

class State {
  constructor() {
    makeAutoObservable(this);
  }

  private _screen: screens = screens.MAIN;
  private _authError: boolean = false;
  private _socket: Socket = null;
  private _chatID: string = null;
  private _chatUpdate: number = 0;
  private _requestUpdate: number = 0;
  private _pushUsersUpdate: number = 0;
  private _admins: Iuser[];
  private _pushType: pushTypes = pushTypes.ALL;
  private _pushParam1: string = '';
  private _pushParam2: string = '';
  private _evaluations: Iestimation[] = [];

  public setScreen(screen: screens): void {
    this._screen = screen;
  }

  public getScreen(): screens {
    return this._screen;
  }

  public setAuthError(error: boolean): void {
    this._authError = error;
  }

  public getAuthError(): boolean {
    return this._authError;
  }

  public socketConnect(): void {
    if (this._socket === null) {
      this._socket = io(process.env.REACT_APP_SOCKET + '?id=' + Utils.getAdminID());
      this._getChatMessage();
      this._getUsersRequests();
      this._chatRead();
    }
  }

  private _chatRead(): void {
    this._socket.on('chat read', () => {
      Chat.clearMarkMessages();
      this.updateChat();
    });
  }

  private _getChatMessage(): void {
    this._socket.on('chat message', (data: Imessage) => {
      data.userId !== Utils.getAdminID() && Actions.soundNotification();
      Actions.getMarks();
      const users = Chat.getChatUsers();
      const check = users.find(user => user.id === data.userId);

      if (check) {

        if (this._chatID === data.userId && this._screen === screens.CHAT) {
          Chat.addMessage(data);
          Actions.markMessages(data.userId);
        } else {
          Chat.addUserMessage(data.userId);
        }
      } else {

        if (Utils.getAdminID() === data.userId) {
          Chat.addMessage(data);
        }
        Chat.addUser(data);
      }
      this.updateChat();
    });
  }

  private _getUsersRequests(): void {
    this._socket.on('chat request', (data: IRequestsUser) => {
      Actions.soundNotification();
      Actions.getMarks();
      const users = Requests.getUsers();
      const check = users.find(user => user.id === data.id);

      if (check) {
        check.mark++;
      } else {
        Requests.addUser(data);
      }
      this.updateReuests();
    });
  }

  public sendChat(message: string): void {
    if (this._chatID === null) return;
    this._socket.emit('chat message', message, { user_to: this._chatID });
  }

  public setChat(id: string): void {
    this._chatID = id;
  }
  
  public getChat(): string {
    return this._chatID;
  }

  public updateChat(): void {
    this._chatUpdate++;
  }

  public getChatUpdate(): number {
    return this._chatUpdate;
  }

  public updateReuests(): void {
    this._requestUpdate++;
  }

  public getRequestsUpdate(): number {
    return this._requestUpdate;
  }

  public getAdmins(): Iuser[] {
    return this._admins;
  }

  public setAdmins(admins: Iuser[]): void {
    this._admins = admins;
  }

  public addAdmin(user: Iuser): void {
    this._admins.push(user);
  }

  public usersPushUpdate(): void {
    this._pushUsersUpdate++;
  }

  public getPushUsersUpdate(): number {
    return this._pushUsersUpdate;
  }

  public getPushType(): pushTypes {
    return this._pushType;
  }

  public setPushType(type: pushTypes): void {
    this._pushType = type;
  }

  public setPushParam1(param: string): void {
    this._pushParam1 = param;
  }

  public getPushParam1(): string {
    return this._pushParam1;
  }

  public setPushParam2(param: string): void {
    this._pushParam2 = param;
  }

  public getPushParam2(): string {
    return this._pushParam2;
  }

  public setEvaluations(evaluations: Iestimation[]): void {
    this._evaluations = evaluations;
  }

  public getEvaluations(): Iestimation[] {
    return this._evaluations;
  }
}

export default new State();