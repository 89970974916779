import { screens } from '../types/enums';

interface Imenu {
  text: string;
  key: screens;
  action: string;
  info?: string;
}

class Utils {

  public getCookie(name: string): string {
    const matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  public setCookie(name: string, value: string, options: IcookieOptions = {}): void {
    options = {
      path: '/',
      ...options
    }
  
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey;
      const optionValue = options[optionKey];

      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }
    document.cookie = updatedCookie;
  }

  public deleteCookie(name: string): void {
    this.setCookie(name, '', {
      'max-age': -1
    })
  }
  
  public getMenu(): Imenu[] {
    return [
      { text: 'Главная', key: screens.MAIN, action: 'Перейти' },
      { text: 'Уведомления', key: screens.NOTIF, action: 'Перейти' },
      { text: 'Заявки', key: screens.REQUESTS, action: 'Перейти' },
      { text: 'Чат', key: screens.CHAT, action: 'Перейти', info: ' не прочитанных' },
      // { text: 'Финансовые продукты', key: screens.FINANCE, action: 'Перейти', info: '5 продуктов' },
      // { text: 'Сервисы', key: screens.SERVICES, action: 'Перейти', info: '5 сервисов' },
      // { text: 'Тарифы', key: screens.RATES, action: 'Перейти', info: '5 тарифов' },
      // { text: 'Пользователи', key: screens.USERS, action: 'Перейти', info: '5 пользователей' },
      { text: 'Учетные записи', key: screens.ACCOUNTS, action: 'Перейти' },
      { text: 'Оценки', key: screens.EVALUATIONS, action: 'Перейти' },
    ];
  }

  public getAdminID(): string {
    return '000000';
  }

  public getUnixtimeDate(data: string): number {
    const parse = data.split('.');
    const date = new Date();
    date.setDate(Number(parse[0]));
    date.setMonth(Number(parse[1]) - 1);
    date.setFullYear(Number(parse[2]));
    const result = Math.floor(date.valueOf() / 1000);
    return result;
  }

  public sortArray<T>(arr: T[], key: keyof T): T[] {
    const isSorted = arr.every((obj, index, array) => {
      if (index === 0) return true;
      return array[index - 1][key] <= obj[key];
    });

    if (isSorted) {
      return [...arr].reverse();
    }
    return [...arr].sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
  }
}

export default new Utils();