import axios from 'axios';
import Chat from './Chat';
import Requests from './Requests';
import State from './State';
import User from './User';
import Utils from './Utils';
import notifications from '../../assets/sounds/notification.mp3';
import Session from './Session';
import { requestStatus } from '../types/enums';

class Actions {

  private async _sendRequest(route: string, data: any): Promise<any> {
    const hash = User.getHash() !== null ? User.getHash() : data.hash;
    const body = {
      ...data,
      hash: hash
    }
    return await axios.post(process.env.REACT_APP_API + '/' + route, body).then(res => res.data);
  }

  public async checkCookie(): Promise<void> {
    const hash = Utils.getCookie('hash');
    
    if (hash && hash !== '') {
      const data: IauthData = await this._sendRequest('checkHash', { hash: hash }).then(data => data);

      if (data.user?.hash === hash) {
        this._setData(data);
      }
    }
  }

  public async checkAuth(login: string, pass: string): Promise<void> {
    const data: IauthData = await this._sendRequest('checkAuth', { login: login, pass: pass }).then(data => data);

    if (data.user?.hash && data.user.hash !== '') {
      this._setData(data);
    } else {
      State.setAuthError(true);
    }
  }

  private _setData(data: IauthData): void {
    User.setUserData(data.user);
    Chat.setChatUsers(data.chatUsers);
    Requests.setUsers(data.requestsUsers);
    State.setAdmins(data.adminUsers);
    State.socketConnect();
  }

  public async getChat(id: string): Promise<void> {
    Chat.setChat([]);
    State.setChat(null);
    const messages: Imessage[] = await this._sendRequest('getChat', { id: id }).then(data => data);
    Chat.clearMarks(id);
    Chat.setChat(messages);
    State.setChat(id);
    State.updateChat();
  }

  public markMessages(id: string): void {
    this._sendRequest('markMessages', { id: id }).then(data => data);
  }

  public async changePassword(oldPass: string, newPass: string): Promise<{ error: boolean, hash?: string }> {
    return this._sendRequest('changePassword', {
      oldPass: oldPass,
      newPass: newPass
    }).then(data => data);
  }

  public async addUser(name: string, login: string, pass: string): Promise<{ error: boolean, typeError: number, user: Iuser }> {
    return this._sendRequest('addUser', {
      name: name,
      login: login,
      pass: pass
    }).then(data => data);
  }

  public async getRequest(id: string): Promise<void> {
    Requests.setUserResquests([]);
    Requests.setUserID(null);
    const requests: IuserRequest[] = await this._sendRequest('getUserResquests', { id: id }).then(data => data);
    Requests.clearMarks(id);
    Requests.setUserResquests(requests);
    Requests.setUserID(id);
    State.updateReuests();
  }

  public sendNotifications(title: string, text: string, users: { id: string, push_token: string }[]): void {
    this._sendRequest('sendPush', {
      title: title,
      text: text,
      users: users
    });
  }

  public soundNotification(): void {
    const audio = new Audio();
    audio.src = notifications;
    audio.autoplay = true;
  }

  public async getMarks(): Promise<void> {
    const data: IauthData = await this._sendRequest('getMarks', {}).then(data => data);
    
    if (Array.isArray(data.chatUsers) && Array.isArray(data.requestsUsers)) {
      Chat.setChatUsers(data.chatUsers);
      Requests.setUsers(data.requestsUsers);
    }
  }

  public titleNotifications(): void {
    const title = document.getElementsByTagName('title')[0];
    const name = 'Админ-панель';
    let show = false, order = false;
    const chatTitle = '***** НОВЫЙ ЧАТ *****';
    const requestsTitle = '***** НОВЫЕ ЗАЯВКИ *****';

    setInterval(() => {
      const requests = Requests.getUnread();
      const chat = Chat.getUnread();
      show = !show;

      if (requests + chat > 0 && show) {

        if (requests > 0 && chat === 0) {
          title.text = requestsTitle;
        } else if (chat > 0 && requests === 0) {
          title.text = chatTitle;
        } else {
          order = !order;
          const text = order ? requestsTitle : chatTitle;
          title.text = text;
        }
      } else if (show === false && title.text !== name) {
        title.text = name;
      }
    }, 500);
  }

  public async getPushUsers(): Promise<void> {
    const users: IpushUser[] = await this._sendRequest('getPushUsers', {}).then(data => data);
    Session.setPushUsers(users);
    State.usersPushUpdate();
  }

  public async markChatMessages(): Promise<void> {
    if (State.getChat()) {
      await this._sendRequest('markMessages', { id: State.getChat() }).then(data => data);
    }
  }

  public async addRequestStatus(): Promise<void> {
    const request = Requests.getID();
    const comment = Requests.getComment();
    const status = Requests.getStatus();

    const req = Requests.getUserResquests().find(data => data.id === request);
    const checkStatus = status in requestStatus;
    const checkComment = typeof comment === 'string' && comment.length > 3;

    if (req && checkStatus && checkComment) {
      this._sendRequest('addRequestStatus', { request, status, comment }).then(data => {
        if (!data.error) {
          req.statuses.push({
            request_id: request,
            comment: comment,
            status: status,
            time: new Date().toString()
          });
          const textarea = document.querySelector('#request-status-textarea') as HTMLTextAreaElement;
          const content = document.querySelector('#requests-statuses-content') as HTMLElement;
          if (textarea) textarea.value = '';
          State.updateReuests();
          alert('Статус добавлен!');
          
          setTimeout(() => {
            if (content) {
              content.scrollTop = content?.scrollHeight;
            }
          }, 500);
        } else {
          alert('Ошибка добавления статуса!');
          window.location.reload();
        }
      });
    } else {
      alert('Ошибка данных! Проверьте все поля');
    }
  }

  public async getEvaluations(): Promise<Iestimation[]> {
    return this._sendRequest('getEvaluations', {}).then(data => data);
  }
}

export default new Actions();