import { useEffect } from 'react';
import '../../assets/css/evaluations.css';
import { observer } from 'mobx-react-lite';
import Actions from '../data/Actions';
import State from '../data/State';
import Utils from '../data/Utils';

export default observer(() => {
  useEffect(() => {
    Actions.getEvaluations().then(res => {
      State.setEvaluations(res);
    });
  }, []);

  const evaluations = State.getEvaluations().map(item => {
    return (
      <tr>
        <td>{ item.id }</td>
        <td>{ item.name }</td>
        <td>{ item.estimation }</td>
      </tr>
    )
  });

  return (
    <div className='evaluations'>
      <div className='evaluations-header sides-padding'>Оценки приложения</div>
      <div className='evaluations-content sides-padding'>
        <table className='sort'>
          <thead>
            <tr>
              <td onClick={() => State.setEvaluations(Utils.sortArray(State.getEvaluations(), 'id'))}>id</td>
              <td onClick={() => State.setEvaluations(Utils.sortArray(State.getEvaluations(), 'name'))}>имя</td>
              <td onClick={() => State.setEvaluations(Utils.sortArray(State.getEvaluations(), 'estimation'))}>оценка</td>
            </tr>
          </thead>
          <tbody>{ evaluations }</tbody>
        </table>
      </div>
    </div>
  );
});